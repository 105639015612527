<script lang="ts" setup>
import Button from '@cypress-design/vue-button'
import { IconGeneralDiffDashed, IconGeneralOfficeBuilding } from '@cypress-design/vue-icon'

const props = withDefaults(
  defineProps<{
    buttonPrimaryText?: string
  }>(),
  {
    buttonPrimaryText: 'Create free account',
  },
)
</script>

<template>
  <div class="my-[24px] flex flex-wrap justify-center gap-[24px] md:mx-[24px]">
    <Button
      href="https://on.cypress.io/set-up-billing"
      target="_blank"
      size="48"
      variant="indigo-dark"
      class="w-[220px] justify-center whitespace-nowrap"
      data-cy="cloud-hero-sign-up"
    >
      <IconGeneralOfficeBuilding fill-color="indigo-600" class="mr-2" />
      {{ buttonPrimaryText }}
    </Button>
    <Button
      href="/pricing"
      size="48"
      variant="outline-light"
      class="w-[220px] justify-center text-indigo-500 hover:text-indigo-600"
      data-cy="cloud-hero-pricing"
    >
      <IconGeneralDiffDashed stroke-color="current" fill-color="indigo-200" class="mr-2 w-[16px]" />
      Compare plans
    </Button>
  </div>
</template>
